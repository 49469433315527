import React, {useCallback, useEffect, useRef, useState} from 'react'
import {io} from "socket.io-client";
import useData from "./useData"
import {RobotContext} from "./RobotContext"
import Collapsible from "./Collapsible"
import RobotMissionBrief from "./RobotMissionBrief"
import RobotControlPanel from "./RobotControlPanel"
import RobotAdminPanel from "./RobotAdminPanel"
import RobotMissionBriefHeader from "./RobotMissionBriefHeader";

import themeLines from './themes/lines.module.scss'
import themeDark from './themes/dark.module.scss'

const themes = {
    lines: themeLines,
    dark: themeDark
}

let socket = null

const Robot = ({robotConfig}) => {
    const [themeName, setThemeName] = useState(robotConfig.theme || 'lines')
    const [overallError, setOverallError] = useState(null)
    const [collapsibleMissionBriefOpen, setCollapsibleMissionBriefOpen] = useState(true)
    const [acceptedMissionBrief, setAcceptedMissionBrief] = useState(false)
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [userCredits, setUserCredits] = useState(false)
    const [userNotices, setUserNotices] = useState([])
    const [userConnectionData, setUserConnectionData] = useState(null)
    const [robotBackends, setRobotBackends] = useState([])
    const [robotConnectionState, setRobotConnectionState] = useState('DISCONNECTED')
    const [robotQuestData, setRobotQuestData] = useState(null)
    const [robotQueueData, setRobotQueueData] = useState(null)
    const [lastNoticedReceived, setLastNoticedReceived] = useState(null)
    const [hasControl, setHasControl] = useState(false)
    const fullScreenElementRef = useRef(null)
    const isFullScreenRef = useRef(null)
    isFullScreenRef.current = isFullScreen

    const { robotId, editMode } = robotConfig
    const robotDataApiEndpoint = editMode ? `/api/v1/user/get_robot_detail/${robotId}?mode=admin_edit` : `/api/v1/user/get_robot_detail/${robotId}`
    const {data: robotDataRaw, error: robotDataError} = useData(robotDataApiEndpoint)

    useEffect(() => {
        if(robotDataError){
            setOverallError(robotDataError)
        }
    }, [robotDataError])

    useEffect(() => {
        if(editMode && robotDataRaw?.admin_data?.backends){
            setRobotBackends(robotDataRaw?.admin_data?.backends)
        }
    }, [robotDataRaw, editMode])

    const lastNoticedReceivedRef = useRef(null)
    lastNoticedReceivedRef.current = lastNoticedReceived
    const userNoticesRef = useRef(null)
    userNoticesRef.current = userNotices
    const fetchUpdatedRobotDataRef = useRef(null)

    useEffect(() => {
        fetchUpdatedRobotDataRef.current = {
            timer: null,
            callback: () => {
                console.log('Fetching robot update data')
                clearTimeout(fetchUpdatedRobotDataRef.current.timer)
                const updatedDataParams = {
                    last_notice_received_id: lastNoticedReceivedRef.current
                }
                fetch(`/api/v1/user/updated_data/${robotId}`, {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updatedDataParams)
                })
                    .then(response => response.json())
                    .then(json => {
                        if (json && json.success) {
                            setRobotQueueData(json.queue)
                            setRobotQuestData(json.quests)
                            setUserCredits(json.credits)

                            const eventsWithTimestamps = json.events.map(event => {
                                return {
                                    ...event,
                                    timestamp: Date.now()
                                }
                            })
                            eventsWithTimestamps.reverse()
                            const newNotices = [
                                ...eventsWithTimestamps,
                                ...userNoticesRef.current
                            ]
                            if(newNotices.length === 0){
                                // always start with some default notice
                                newNotices.push({
                                    timestamp: Date.now(),
                                    id: 'default',
                                    message: `You have ${parseInt(json.credits,10)} credits remaining.`
                                })
                            }else if(newNotices.length > 15) {
                                newNotices.pop()
                            }
                            userNoticesRef.current = newNotices
                            setUserNotices(newNotices)
                            setLastNoticedReceived(json.event_last_id)
                            setHasControl(json.has_control)
                        } else {
                            console.error('Failed to refresh robot data', e)
                        }
                    })
                    .catch(e => {
                        console.error('Failed to refresh robot data', e)
                    })

                fetchUpdatedRobotDataRef.current.timer = setTimeout(fetchUpdatedRobotDataRef.current.callback, 10000)
            }
        }
    }, [])


    const connectToRobotCallback = useCallback(() => {
        // todo: connectMethod is `connect` or `view`
        setAcceptedMissionBrief(true)
        setCollapsibleMissionBriefOpen(false)
        setRobotConnectionState("CONNECTING")

        // kick this off first, so it builds the queue data up and we ge the notice ID that we've spent credits first up.
        fetchUpdatedRobotDataRef.current.callback() // start the kick off

        fetch(`/api/v1/user/connect/${robotId}`)
            .then(response => response.json())
            .then(json => {
                if (json && json.success) {

                    // we've got API connection data, lets see if it's successful and if so, connect socket.io up.
                    if (
                        json.robot_id &&
                        json.user_id
                    ) {
                        // We only support a single socket host per robot, so we're not dealing with weird
                        // traffic splits between multiple socket servers in different geos
                        const socketHost = json.hosts.find(b => b.type === 'SOCKET')
                        // const socketRtsp = json.hosts.find(b => b.type === 'RTSP') // TODO
                        if (!socketHost) {
                            setRobotConnectionState("ERROR")
                            throw new Error('Unable to find socket host')
                        }
                        const socketUri = `${socketHost.endpoint}/v1/stream_robot`
                        socket = io(socketUri, {transports: ["websocket"]})
                        const streamUserId = socketHost.stream_user_id

                        setUserConnectionData({
                            userId: json.user_id,
                            streamUserId: streamUserId
                        })
                        setRobotBackends(json.backends)

                        socket.on("welcome", async () => {
                            console.log("Socket Authentication: starting")
                            socket
                                .emitWithAck("authenticate", {
                                    actor: "user",
                                    robot_id: robotConfig.robotId,
                                    stream_robot_id: socketHost.stream_robot_id,
                                    stream_user_id: streamUserId,
                                    stream_user_key: socketHost.stream_user_key
                                })
                                .then(authResult => {
                                    if (!authResult || !authResult.success) {
                                        console.error("Socket Authentication: failed", authResult)
                                        setRobotConnectionState("ERROR")
                                        setOverallError({
                                            message: "Auth failed",
                                            error: authResult
                                        })
                                        throw Error("No success response")
                                    }
                                    console.log("Socket Authentication: success", authResult)

                                    setRobotConnectionState("CONNECTED")
                                }).catch(e => {
                                console.error('Failed to authenticate', e)
                                setRobotConnectionState("ERROR")
                                setOverallError(e)
                                throw Error(`Failed auth ${e}`)
                            })
                        })
                        socket.io.on("error", (error) => {
                            console.error("Socket error", error)
                        })
                        socket.on("disconnect", (error) => {
                            console.error("Socket disconnect", error)
                        })
                        socket.on("robot_data_update", () => {
                            console.log('Server is telling us to fetch a fresh update for the UI')
                            fetchUpdatedRobotDataRef.current.callback()
                        })
                        socket.on("robot_disconnected", () => {
                            console.log("client robot disconnected")
                            setOverallError({
                                message: "Robot went offline, please refresh and try again."
                            })
                            setRobotConnectionState("ERROR")
                        })
                    }
                } else {
                    if(json && json.error){
                        alert(json.error)
                    }
                    setRobotConnectionState("ERROR")
                }
            })
            .catch(e => {
                console.error('Failed to connect to robot data', e)
                setOverallError({
                    message: "Failed connection.",
                    error: e
                })
                setRobotConnectionState("ERROR")
            })
    }, [])


    useEffect(() => {
        console.log('connection state changed', robotConnectionState)
        if (robotConnectionState === "DISCONNECTING" || robotConnectionState === "ERROR") {
            // disconnect and clean up stuff
            console.log("disconnecting sockets etc..")
            clearTimeout(fetchUpdatedRobotDataRef.current.timer)
            socket && socket.disconnect()
            setCollapsibleMissionBriefOpen(true)
            if (isFullScreenRef.current) {
                toggleFullScreen()
            }
            setRobotConnectionState("DISCONNECTED")
        }
    }, [robotConnectionState])


    const fullscreenchanged = () => {
        if (document.fullscreenElement) {
            screen.orientation.lock("landscape")
                .catch((error) => {
                    console.error('Failed to lock landscape mode', error)
                })
            setIsFullScreen(true)
        } else {
            screen.orientation.unlock()
            setIsFullScreen(false)
        }
    }

    const toggleFullScreen = () => {
        if (document.fullscreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        } else {
            fullScreenElementRef.current.removeEventListener("fullscreenchange", fullscreenchanged);
            fullScreenElementRef.current.addEventListener("fullscreenchange", fullscreenchanged);
            if (fullScreenElementRef.current.requestFullscreen) {
                fullScreenElementRef.current.requestFullscreen();
            } else if (fullScreenElementRef.current.webkitRequestFullscreen) { /* Safari */
                fullScreenElementRef.current.webkitRequestFullscreen();
            } else if (fullScreenElementRef.current.msRequestFullscreen) { /* IE11 */
                fullScreenElementRef.current.msRequestFullscreen();
            } else if (fullScreenElementRef.current.webkitEnterFullscreen) { /* ios */
                fullScreenElementRef.current.webkitEnterFullscreen();
            } else {
                alert('Sorry your device does not support full screen mode')
            }
        }
    }

    const styles = themes[themeName]

    const disconnectRobot = () => {
        setRobotConnectionState("DISCONNECTING")
    }

    if (robotDataError) {
        return (
            <div className={styles.loading}>
                Error:
                {JSON.stringify(robotDataError)}
            </div>
        )
    }

    if (!robotDataRaw) {
        return (
            <div className={styles.loading}>Loading...</div>
        )
    }
    // if (connectDataError) {
    //     return (
    //         <div className={styles.loading}>Error Connecting To Robot
    //             {JSON.stringify(connectDataError)}
    //         </div>
    //     )
    // }

    return (
        <RobotContext.Provider value={{
            editMode: editMode,
            robotData: robotDataRaw.robot,
            robotQuestData: robotQuestData,
            robotQueueData: robotQueueData,
            userId: userConnectionData?.userId,
            streamUserId: userConnectionData?.streamUserId,
            robotBackends: robotBackends,
            socket: socket,
            styles: styles,
            isFullScreen: isFullScreen,
            userCredits: userCredits,
            userNotices: userNotices,
            fetchUpdatedRobotDataRef: fetchUpdatedRobotDataRef,
            hasControl: hasControl
        }}>
            <div className={styles.background}>
                <div className={styles.overallWrapper} ref={fullScreenElementRef}>
                    <Collapsible
                        title={(
                            <RobotMissionBriefHeader
                                isExpanded={collapsibleMissionBriefOpen}
                                robotConnectionState={robotConnectionState}
                                toggleFullScreen={toggleFullScreen}
                                disconnectRobot={disconnectRobot}
                            ></RobotMissionBriefHeader>
                        )}
                        open={collapsibleMissionBriefOpen}
                        setOpen={() => {
                            setCollapsibleMissionBriefOpen(!collapsibleMissionBriefOpen)
                        }}>

                        <RobotMissionBrief
                            robotConnectionState={robotConnectionState}
                            connectRobot={connectToRobotCallback}
                            toggleFullScreen={toggleFullScreen}
                            disconnectRobot={disconnectRobot}></RobotMissionBrief>
                    </Collapsible>

                    {editMode ? (
                        <RobotAdminPanel></RobotAdminPanel>
                    ) : (
                        <>
                            {acceptedMissionBrief && (
                                <>
                                    {robotConnectionState === "CONNECTING" && <div>Connecting...</div>}
                                    {robotConnectionState === "DISCONNECTED" && <div>Disconnected...</div>}
                                    {robotConnectionState === "ERROR" && <div>Error Connecting...</div>}
                                    {robotConnectionState === "CONNECTED" && (
                                        <RobotControlPanel></RobotControlPanel>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </RobotContext.Provider>
    )
}

export default Robot
