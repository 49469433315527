
export const availableSlots = {
    video: [
        { key: 'VIDEO_MAIN', name: 'Video Main' },
        { key: 'VIDEO_LEFT', name: 'Video Left' },
        { key: 'VIDEO_RIGHT', name: 'Video Right' },
        { key: 'VIDEO_FULL', name: 'Video Full' },
    ],
    info: [
        { key: 'SIDEBAR_LEFT_VISIBLE', name: 'Left Sidebar' },
        { key: 'SIDEBAR_RIGHT_VISIBLE', name: 'Right Sidebar' },
    ],
    control: [
        { key: 'BOTTOM_LEFT', name: 'Bottom Left' },
        { key: 'BOTTOM_RIGHT', name: 'Bottom Right' },
    ]
}
