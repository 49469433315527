
const digest = '6a3ba0806dc5a5506bfb7fbc9377fb126d8d4af3cf5f828864efad5b2cf67247';
const classes = {"background":"_background_1wrek_1","overallWrapper":"_overallWrapper_1wrek_9","disconnectButton":"_disconnectButton_1wrek_30","niceBox":"_niceBox_1wrek_37","niceBoxHeader":"_niceBoxHeader_1wrek_47","niceBoxContent":"_niceBoxContent_1wrek_61","collapsibleHeader":"_collapsibleHeader_1wrek_68","collapsibleHidden":"_collapsibleHidden_1wrek_72","robotControl":"_robotControl_1wrek_76","robotControlFullScreen":"_robotControlFullScreen_1wrek_87","mainVideoAndControls":"_mainVideoAndControls_1wrek_92","overlayControlLeft":"_overlayControlLeft_1wrek_100","overlayControlRight":"_overlayControlRight_1wrek_107","mainSidebar":"_mainSidebar_1wrek_114","missionBriefHeader":"_missionBriefHeader_1wrek_118","missionBriefHeaderText":"_missionBriefHeaderText_1wrek_123","missionBriefHeaderActions":"_missionBriefHeaderActions_1wrek_127","missionBriefWrapper":"_missionBriefWrapper_1wrek_134","imageWrapper":"_imageWrapper_1wrek_147","multiPanelHolder":"_multiPanelHolder_1wrek_160","multiPanelChild":"_multiPanelChild_1wrek_170","multiPanelChildFocused":"_multiPanelChildFocused_1wrek_175","multiPanelChildHidden":"_multiPanelChildHidden_1wrek_183","fullImage":"_fullImage_1wrek_195","queueMyEntry":"_queueMyEntry_1wrek_202","robotLocked":"_robotLocked_1wrek_206","eventList":"_eventList_1wrek_221","eventTimestamp":"_eventTimestamp_1wrek_234","questTitle":"_questTitle_1wrek_243","videoPlayer":"_videoPlayer_1wrek_249","videoPlayerCanvas":"_videoPlayerCanvas_1wrek_254","uiWrapper":"_uiWrapper_1wrek_260","uiGrid":"_uiGrid_1wrek_266","uiComponent":"_uiComponent_1wrek_278"};
const css = `._background_1wrek_1 {
  color: #ffffff;
  font-size: 16px;
}
._background_1wrek_1 strong {
  font-weight: 700;
}

._overallWrapper_1wrek_9 {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

._overallWrapper_1wrek_9 button {
  display: inline-block;
  outline: 0;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  padding: 0.5rem 0.75rem;
  color: #ffffff;
  background-color: rgb(59, 130, 246);
}
._overallWrapper_1wrek_9 button:hover {
  background: rgb(37, 99, 235);
}
._overallWrapper_1wrek_9 button._disconnectButton_1wrek_30 {
  background-color: rgb(185, 28, 28);
}
._overallWrapper_1wrek_9 button._disconnectButton_1wrek_30:hover {
  background: rgb(153, 27, 27);
}

._niceBox_1wrek_37 {
  border: 1px solid rgba(29, 28, 29, 0.3019607843);
  border-radius: 6px;
  margin-bottom: 1rem;
  background-color: rgb(30, 41, 59);
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

._niceBoxHeader_1wrek_47 {
  padding: 1rem;
  background-color: rgb(30, 41, 59);
  color: #ffffff;
  border-bottom: 1px dashed rgb(51, 65, 85);
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  font-size: 16px;
}
[data-collapsed=true] ._niceBoxHeader_1wrek_47 {
  border-bottom: 0;
  border-radius: 6px;
}

._niceBoxContent_1wrek_61 {
  overflow: hidden;
  flex: 1;
  position: relative;
  padding: 1rem;
}

._collapsibleHeader_1wrek_68 {
  cursor: pointer;
}

._collapsibleHidden_1wrek_72 {
  display: none;
}

._robotControl_1wrek_76 {
  position: relative;
  flex: 1;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-template-rows: repeat(1, minmax(0, 1fr));
  min-height: 400px;
  user-select: none;
}

._robotControlFullScreen_1wrek_87 {
  height: calc(100vh - 20px);
  padding: 1rem;
}

._mainVideoAndControls_1wrek_92 {
  position: relative;
  min-height: 80vh;
  max-height: 100vh;
  grid-row: span 4/span 4;
  grid-column: span 8/span 8;
}

._overlayControlLeft_1wrek_100 {
  z-index: 30;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
}

._overlayControlRight_1wrek_107 {
  z-index: 30;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

._mainSidebar_1wrek_114 {
  grid-column: span 4/span 4;
}

._missionBriefHeader_1wrek_118 {
  display: flex;
  align-items: center;
}

._missionBriefHeaderText_1wrek_123 {
  margin: 0 auto 0 0;
}

._missionBriefHeaderActions_1wrek_127 {
  margin: 0 0 0 auto;
}
._missionBriefHeaderActions_1wrek_127 button {
  margin-left: 10px;
}

._missionBriefWrapper_1wrek_134 {
  display: flex;
  flex-wrap: wrap;
}
._missionBriefWrapper_1wrek_134 div {
  padding: 5px;
  flex: 0 0 50%;
}
@media (min-width: 840px) {
  ._missionBriefWrapper_1wrek_134 div {
    flex: 0 0 33%;
  }
}
._missionBriefWrapper_1wrek_134 ._imageWrapper_1wrek_147 {
  text-align: center;
  flex: 0 0 100%;
}
@media (min-width: 840px) {
  ._missionBriefWrapper_1wrek_134 ._imageWrapper_1wrek_147 {
    flex: 0 0 33%;
  }
}
._missionBriefWrapper_1wrek_134 ._imageWrapper_1wrek_147 img {
  display: inline-block;
}

._multiPanelHolder_1wrek_160 {
  height: 100%;
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
  /*right: 0;*/
  /*bottom: 0;*/
  /*overflow: hidden;*/
}

._multiPanelChild_1wrek_170 {
  height: 100%;
  /*position: absolute;*/
}

._multiPanelChildFocused_1wrek_175 {
  /*z-index: 10;*/
  /*top: 0;*/
  /*left: 0;*/
  /*right: 0;*/
  /*bottom: 0;*/
}

._multiPanelChildHidden_1wrek_183 {
  top: 10px;
  right: 10px;
  z-index: 11;
  width: 250px;
  height: 170px;
  position: absolute;
  cursor: pointer;
  opacity: 0.5;
  border: 2px solid #fff;
}

._fullImage_1wrek_195 {
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

._queueMyEntry_1wrek_202 {
  font-weight: 700;
}

._robotLocked_1wrek_206 {
  position: absolute;
  z-index: 100;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
._robotLocked_1wrek_206 svg {
  width: 200px;
  height: auto;
  opacity: 0.6;
}

._eventList_1wrek_221 {
  max-height: 180px;
  overflow-y: auto;
}
._eventList_1wrek_221 li {
  border-bottom: 1px solid #efefef;
  padding-bottom: 8px;
  padding-top: 8px;
}
._eventList_1wrek_221 li:last-child {
  border-bottom: 0;
}

._eventTimestamp_1wrek_234 {
  display: block;
  font-size: 10px;
  background: #2c394d;
  padding: 4px;
  border-radius: 4px;
  margin-bottom: 4px;
}

._questTitle_1wrek_243 {
  border-bottom: 1px solid #efefef;
  padding-bottom: 8px;
  padding-top: 8px;
}

._videoPlayer_1wrek_249 {
  width: 100%;
  height: 100%;
}

._videoPlayerCanvas_1wrek_254 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

._uiWrapper_1wrek_260 {
  aspect-ratio: 16/9;
  position: relative;
  background-color: rgb(30, 41, 59);
}

._uiGrid_1wrek_266 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: [far-left] 250px [mid-left] 1fr [middle] 1fr [mid-right] 250px [far-right];
  grid-template-rows: [far-top] 250px [mid-top] 1fr [mid-bottom] 250px [far-bottom];
  gap: 15px;
}

._uiComponent_1wrek_278 {
  border: 3px dashed rgba(204, 204, 204, 0.21);
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
._uiComponent_1wrek_278[data-ui-size=AUTO], ._uiComponent_1wrek_278[data-ui-size=FULL] {
  grid-column-start: far-left;
  grid-column-end: far-right;
  grid-row-start: far-top;
  grid-row-end: far-bottom;
}
._uiComponent_1wrek_278[data-ui-size=MAIN] {
  margin: 20px;
}
._uiComponent_1wrek_278[data-ui-size=MAIN][data-ui-slot=AUTO], ._uiComponent_1wrek_278[data-ui-size=MAIN][data-ui-slot=TOP_LEFT], ._uiComponent_1wrek_278[data-ui-size=MAIN][data-ui-slot=BOTTOM_LEFT] {
  grid-column-start: far-left;
  grid-column-end: mid-right;
  grid-row-start: far-top;
  grid-row-end: far-bottom;
}
._uiComponent_1wrek_278[data-ui-size=MAIN][data-ui-slot=TOP_RIGHT], ._uiComponent_1wrek_278[data-ui-size=MAIN][data-ui-slot=BOTTOM_RIGHT] {
  grid-column-start: mid-left;
  grid-column-end: far-right;
  grid-row-start: far-top;
  grid-row-end: far-bottom;
}
._uiComponent_1wrek_278[data-ui-size=HALF] {
  margin: 30px;
}
._uiComponent_1wrek_278[data-ui-size=HALF][data-ui-slot=AUTO], ._uiComponent_1wrek_278[data-ui-size=HALF][data-ui-slot=TOP_LEFT], ._uiComponent_1wrek_278[data-ui-size=HALF][data-ui-slot=BOTTOM_LEFT] {
  grid-column-start: far-left;
  grid-column-end: middle;
  grid-row-start: far-top;
  grid-row-end: far-bottom;
}
._uiComponent_1wrek_278[data-ui-size=HALF][data-ui-slot=TOP_RIGHT], ._uiComponent_1wrek_278[data-ui-size=HALF][data-ui-slot=BOTTOM_RIGHT] {
  grid-column-start: middle;
  grid-column-end: far-right;
  grid-row-start: far-top;
  grid-row-end: far-bottom;
}
._uiComponent_1wrek_278[data-ui-size=SMALL] {
  margin: 40px;
}
._uiComponent_1wrek_278[data-ui-size=SMALL][data-ui-slot=AUTO], ._uiComponent_1wrek_278[data-ui-size=SMALL][data-ui-slot=TOP_LEFT] {
  grid-column-start: far-left;
  grid-column-end: mid-left;
  grid-row-start: far-top;
  grid-row-end: mid-top;
}
._uiComponent_1wrek_278[data-ui-size=SMALL][data-ui-slot=BOTTOM_LEFT] {
  grid-column-start: far-left;
  grid-column-end: mid-left;
  grid-row-start: mid-bottom;
  grid-row-end: far-bottom;
}
._uiComponent_1wrek_278[data-ui-size=SMALL][data-ui-slot=TOP_RIGHT] {
  grid-column-start: mid-right;
  grid-column-end: far-right;
  grid-row-start: far-top;
  grid-row-end: mid-top;
}
._uiComponent_1wrek_278[data-ui-size=SMALL][data-ui-slot=BOTTOM_RIGHT] {
  grid-column-start: mid-right;
  grid-column-end: far-right;
  grid-row-start: mid-bottom;
  grid-row-end: far-bottom;
}`;

(function() {
  if (typeof document !== "undefined" && !document.getElementById(digest)) {
    var ele = document.createElement('style');
    ele.id = digest;
    ele.textContent = css;
    document.head.appendChild(ele);
  }
})();

export default classes;
export { css, digest, classes };
  