import React, {useContext, useEffect, useState} from 'react'
import {RobotContext} from "./RobotContext"
import UiComponent from "./UiComponent";
import {availableSlots} from "../lib/slots";

const UiWrapper = ({robotBackends}) => {
    const {styles} = useContext(RobotContext)

    /*
    Layers:
    - Video = video feeds, go underneath all
    - Info = left or right sidebar for information
    - Control = joysticks and stuff that go over everything else
     */

    const backendsBySlot = Object.groupBy(robotBackends, ({ ui_slot }) => ui_slot)

    console.log(backendsBySlot)

    return (
        <div className={styles.uiWrapper}>
            <div className={styles.uiGrid}>
                {/* First render any video backends */}
                {availableSlots.video.map(slot => {
                    if(!backendsBySlot[slot.key])return

                    return (
                        <UiComponent
                            key={slot.key}
                            robotBackends={backendsBySlot[slot.key]}
                            uiSlot={slot.key}
                        />
                    )
                })}
                {/* Then any info sidebars */}
                {availableSlots.info.map(slot => {
                    if(!backendsBySlot[slot.key])return

                    return (
                        <UiComponent
                            key={slot.key}
                            robotBackends={backendsBySlot[slot.key]}
                            uiSlot={slot.key}
                        />
                    )
                })}
                {/* Finally any controls on top */}
                {availableSlots.control.map(slot => {
                    if(!backendsBySlot[slot.key])return

                    return (
                        <UiComponent
                            key={slot.key}
                            robotBackends={backendsBySlot[slot.key]}
                            uiSlot={slot.key}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default UiWrapper
