import React, {useCallback, useContext, useEffect, useState} from 'react'
import Collapsible from "./Collapsible";;
import {RobotContext} from "./RobotContext";
import MultiPanelHolder from "./backends/MultiPanelHolder";
import UiWrapper from "./UiWrapper";
import {availableSlots} from "../lib/slots";

const RobotAdminPanel = () => {
    const {styles, robotData, isFullScreen, robotBackends, robotQueueData, userCredits, hasControl} = useContext(RobotContext)

    const [editBackendId, setEditBackendId] = useState(null)
    const [robotBackendsToEdit, setRobotBackendsToEdit] = useState([])
    useEffect(() => {
        setRobotBackendsToEdit(robotBackends)
    }, [robotBackends])

    const uiSlotChangeCallback = useCallback(({robotBackendId, uiSlot, uiSize}) => {
        setRobotBackendsToEdit(prev => prev.map(prevRobotBackend => {
            if (robotBackendId === prevRobotBackend.robot_backend_id) {
                return {
                    ...prevRobotBackend,
                    ui_slot: uiSlot,
                    ui_size: uiSize
                }
            }
            return prevRobotBackend
        }))
    }, [])


    const backendsBySlot = Object.groupBy(robotBackendsToEdit, ({ ui_slot }) => ui_slot)


    return (
        <div className={styles.robotAdminPanel}>
            {robotBackendsToEdit.length > 0 ? (
                <>
                    <h3>Edit backends:</h3>
                    <div>
                        <h3>Video Backends:</h3>
                        {backendsBySlot.video.map(rb => (
                            <div key={rb.robot_backend_id}>
                                {rb.type}: {rb.robot_backend_id}
                            </div>
                        ))}
                    </div>

                    <UiWrapper robotBackends={robotBackendsToEdit}></UiWrapper>
                </>
            ) : null}
        </div>
    )
}

export default RobotAdminPanel
