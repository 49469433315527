import React, {useContext, useEffect, useState} from 'react'
import {RobotContext} from "./RobotContext";

const UiComponent = ({robotBackends, uiSlot}) => {
    const {styles} = useContext(RobotContext)

    return (
        <div className={styles.uiComponent} data-ui-slot={uiSlot}>
            {JSON.stringify(robotBackends)}
        </div>
    )
}

export default UiComponent
